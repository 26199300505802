/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    openOtp: false,
    time: 0,
    manySend: 1,
    latestPhone: '',
    type: 'whatsapp',
    maxOtpSession: null,
    language: 'en',
    config: null,
    withUtm: false,
    utmData: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetOtp(state) {
            state.openOtp = false;
            state.time = 0;
            state.manySend = 1;
            state.latestPhone = '';
            state.type = '';
            state.maxOtpSession = null;
            state.language = 'en';
        },
        setOpenOtp(state, action) {
            state.openOtp = action.payload;
        },
        setTime(state, action) {
            state.time = action.payload;
        },
        setManySend(state, action) {
            state.manySend = action.payload;
        },
        setLatesPhone(state, action) {
            state.latestPhone = action.payload;
        },
        setType(state, action) {
            state.type = action.payload;
        },
        setMaxOtpSession(state, action) {
            state.maxOtpSession = action.payload;
        },
        setLanguage(state, action) {
            state.language = action.payload || 'en';
        },
        setConfig(state, action) {
            state.config = action.payload;
        },
        setWithUtm(state, action) {
            state.withUtm = action.payload;
        },
        setUtmData(state, action) {
            state.utmData = action.payload;
        },
    },
});

export const {
    resetOtp,
    setLatesPhone,
    setManySend,
    setTime,
    setOpenOtp,
    setType,
    setMaxOtpSession,
    setLanguage,
    setConfig,
    setWithUtm,
    setUtmData,
} = authSlice.actions;

const authReducer = authSlice.reducer;
export default authReducer;
